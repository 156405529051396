.contact-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.contact-container h2 {
  margin-bottom: 20px;
}

.contact-container form {
  display: flex;
  flex-direction: column;
}

.contact-container input,
.contact-container textarea {
  margin-bottom: 10px;
  padding: 10px;
  border: none;
  border-radius: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3) !important; /* Ensure box-shadow is applied */
  width: 100%; /* Ensure full width */
}

.contact-container button {
  padding: 10px;
  background-color: #57B5C8;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-container button:hover {
  background-color: #3F8EA5;
}

textarea {
  font-family: Arial, Helvetica, sans-serif;
}

.contact-container input:focus,
.contact-container textarea:focus {
  outline: none; /* Removes the default outline */
  border-color: #57B5C8; /* Sets the border color to a blue shade */
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.5) !important; /* Optional: Adds a glow effect */
}

.header-block {
  background-color: #E63827;
  width: 100%;
  height: 200px;
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .contact-container input,
  .contact-container textarea {
    width: 90%; /* Ensure full width minus some padding */
    margin: 0 auto 10px auto; /* Center align and add bottom margin */
    padding: 10px;
    border-radius: 20px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3) !important; /* Ensure box-shadow is applied */
  }
}
