.footer-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content:space-evenly;
    align-items: center;
    bottom: 0;
    margin-left: 300px;
    width: 80%;
    z-index: 100;
    height: fit-content;
    color: black;

}

@media (max-width: 900px) {
    .footer-container {
        margin-left: 0;
    }
}
