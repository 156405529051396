.slider-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center children horizontally */
    width: 50%;
    /* margin-left: 10px; */
    margin: 0 auto; /* Center the slider on the page */
  }

  .arrow-photo-arrow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
  }

.photos {
    width: 70%;
    min-width: 550px;
    max-width: 600px; /* Limit maximum width */
    height: auto; /* Maintain aspect ratio */    flex-shrink: 0;
    border-radius: 30px;
    margin: 0px;
    scroll-behavior: smooth;
    overflow-y: scroll;
    display: flex;
    margin-right: 20px;
    overflow-x: auto; /* Enables horizontal scrolling */
  scroll-snap-type: x mandatory; /* Optional: Enables snap scrolling for each tile */
  }

  .slider {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: center;
    width: 100%;
    margin: 20px 0;  }
  

    .tile {
      flex: 0 0 auto; /* Do not grow, do not shrink, base size on content */
      width: calc(95% - 50px); /* Subtract padding/margin */
      padding: 10px;
      margin: 0 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 40px;
      background-color: aliceblue;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    }  
  /* Add your existing styles here */

  .tile img {
    width: 100%; /* Ensures the image takes the full width of the container */
    height: auto; /* Keeps the aspect ratio intact */
    object-fit: cover; /* Covers the area, might crop the image */
    border-radius: 15px; /* Matches the container's border-radius */
    display: block; /* Removes bottom space/gap under the image */
  }
  

.slider-controls {
    text-align: center;
  }
  
  .slider-arrow {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .slider-arrow:hover {
    color: #333;
  }
  
  .slider-dots {
    text-align: center;
    padding-top: 0px;
  }
  
  .slider-dot {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #ccc;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    }
    
    .slider-dot.active {
    background-color: #333;
    }

/* Responsive adjustments */
@media (max-width: 1200px) {
  .tile {
    width: 60%; /* Make tile slightly smaller */
    padding: 10px;
    border-radius: 30px;
  }

}


@media (max-width: 768px) {
  .arrow-photo-arrow {
    width: 50%;
  }

  .tile img {
    object-fit: contain; 
  }

  .tile {
    width: 50%; 
    padding: 10px;
    border-radius: 30px;
  }

  .slider-arrow {
    font-size: 1em; 
  }

  .slider-dot {
    width: 8px;
    height: 8px;
  }
}

@media (max-width: 700px) {
  .arrow-photo-arrow, .slider-dot {
    display: none;
  }

}
    
  