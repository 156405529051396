.about-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: auto;
  max-width: 1200px;
  padding: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  min-width: 320px;
}

.about-column {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  background: #f9f9f9; /* Light grey background */
  border-radius: 8px; /* Rounds the corners of the columns */
  flex-basis: 50%; /* Takes up roughly half the container width */
  margin: 10px;
  padding: 20px;
}

.about-column h2, .about-column h3 {
  color: #333; /* Dark text for headings */
  margin-bottom: 0.5em;
}

.about-column p, .about-column ul {
  color: #555; /* Slightly lighter text for body */
  line-height:1.6; /* Spacing for readability */
margin-bottom: 1em;
}

.about-column ul {
list-style-type: none; /* No bullets for a cleaner look /
padding-left: 0; / Aligns text with the rest of the content */
}

.about-column li {
margin-bottom: 0.5em;
padding-left: 1.5em;
position: relative;
}

.about-column li::before {
content: '•'; /* Adds a custom bullet /
color: #007bff; / Bullet color */
font-size: large;
position: absolute;
left: 0;
}

/* Add additional styles for buttons, links, and other elements as needed. */

.header-block {
  background: url('../Assets/Group\ 21.png') center center no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.3);
  display: flex;
}

.header-block img {
  width: 400px;
  object-fit: contain;
  border-radius: 15px;
  display: block;
  margin: 20px auto;
}

.badge-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  padding: 10px;
  border-radius: 5px;
  max-width: 100%;
  min-width: 320px;
}

.badge {
  display: flex;
  padding: 8px 16px;
  font-size: 16px;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  width: 10%;
}

.junior-bronze { background-color: #6a7ba2; } /* Dark bluish-grey */
.bronze       { background-color: #6a7ba2; } /* Bronze */
.silver       { background-color: #6a7ba2; } /* Silver */
.gold         { background-color: #6a7ba2; } /* Gold */
.platinum     { background-color: #6a7ba2; } /* Platinum grey */

.screenshot-container {
  flex-basis: 400px; /* Width of the image container */
  display: flex;
  justify-content: center; /* Center the image within its container */
  align-items: center; /* This will vertically center the image if needed */
  margin-bottom: 20px;
  border-radius: 8px; /* Rounds the corners of the image */
}

.training-column {
  display: flex;
  margin: 20px;
  background: #f9f9f9; /* Light grey background */
  border-radius: 8px; /* Rounds the corners of the columns */
  margin: 10px;
  padding: 20px;

}

.training-content {
  display: flex;
  align-items: center; /* Aligns items vertically in the center */
  justify-content: space-between; /* Spreads the content evenly */
}

.training-text {
  flex: 1; /* Takes up remaining space not used by the image */
  padding-right: 20px; /* Adds some space between the text and the image */
}

.training-image {
  flex-basis: 400px; /* Width of the image container */
  display: flex;
  justify-content: center; /* Center the image within its container */
  align-items: center; /* This will vertically center the image if needed */
  margin-bottom: 20px;
  border-radius: 8px; /* Rounds the corners of the image */
}


/* Responsive adjustments */

@media (max-width: 900px) {
  .about-container {
    flex-basis: 100%;
  }

  .training-content {
    flex-basis: 100%; /* Each column takes full width on smaller screens */
    flex-direction: column;
    }
    
}

img {
  border-radius: 20px;
}

@media (max-width: 768px) {
  .about-column {
  flex-basis: 100%; /* Each column takes full width on smaller screens */
  }
  .training-content {
  flex-basis: 100%; /* Each column takes full width on smaller screens */
  flex-direction: column;
  }
  .badge {
    font-size: 14px;
  }
  
  }
  

