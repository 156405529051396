body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  background-color: white;
  overflow-y: scroll;
  overflow-x: hidden;
}


.App {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  font-family: 'Poppins', sans-serif;
  overflow: hidden;
}

.Sidebar {
  width: 250px;
  min-height: 100%;
  background-color: #01B0F1;

  position: fixed;
  top: 0; 
  overflow-y: hidden; 
}



.SidebarList {
  height: 50%;
  flex-shrink: 0;
    padding: 0;
    background: #01B0F1;
}

.SidebarList .row {
  height: 60px;
  list-style-type: none;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: whitesmoke;
  text-decoration: none; 
}

.SidebarList .row a { 
  height: 60px;
  width: 100%;

  color: whitesmoke; 
  text-decoration: none; 
  display: flex;
  align-items: center;
justify-content: center;
flex-shrink: 0;
font-family: 'Russo One', sans-serif;

font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.SidebarList .row:hover {
  background-color: #0089bb;
  cursor: pointer;
}

.SidebarList .row a.active {
  background-color: #0089bb;

}

.row #icon {
  flex: 30%;
  display: grid;
  place-items: center;
padding: 0 30px;
}

.row #title {
  flex: 70%;
}

.content {
  flex-grow: 1; 
  height: 100%; 
  margin-left: 250px; 
}

@media (max-width: 900px) {
  .body {
    overflow-y: scroll;
  overflow-x: scroll;
  }
  .Sidebar {
    position: relative; 
    display: none; 
  }

  .small-sidebar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    background-color: #01B0F1;
    color: whitesmoke;
  }

  .content {
    margin-left: 0; 
  }
}
