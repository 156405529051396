.top-nav {
    background-color: #01B0F1;
    color: white;
    padding: 10px;
    position: relative;
    text-align: left;
    max-height: 40px;
  }

  .menu-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

  }
  
  .menu-icon {
    cursor: pointer;
    font-size: 24px;
  }
  
  .menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: none;
    width: 100%;
    background-color: #01B0F1;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
  }
  
  .menu.show {
    display: block;
  }
  
  .menu li a {
    color: white;
    text-decoration: none;
    display: block;
    padding: 10px;
    transition: background-color 0.3s;
  }
  
  .menu li a:hover {
    background-color: #555;
  }
  
  @media screen and (min-width: 900px) {
    .menu {
      display: none;
    }
    .top-nav {
        display: none;
    }
    
  }