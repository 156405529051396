.links {
  display: flex;
  justify-content: center;
  align-items: center;

}

.links-container {
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  width: 60%
}

.link-item {
  display: block;  
  margin-bottom: 10px;
  color: blue;
  text-decoration: none;  
}

.link-item:hover {
  text-decoration: underline;  
}
 @media (max-width: 768px) {
  .links-container {
    width: 90%;
  }
}